import type { ComponentProps, ElementRef } from "react";
import { forwardRef } from "react";
import { t } from "ttag";

import { SUBSCRIPTION_DURATION_IN_MONTHS } from "@/shared.constants";
import type { SubscriptionPlan } from "@/shared.types";
import { RadioGroupCards } from "@/ui";

const getPaymentDescription = (
  price: SubscriptionPlan["price"],
  durationInMonths: SubscriptionPlan["durationInMonths"],
) => {
  const period = {
    [SUBSCRIPTION_DURATION_IN_MONTHS.MONTHLY]: t`month`,
    [SUBSCRIPTION_DURATION_IN_MONTHS.QUARTERLY]: t`three months`,
  }[durationInMonths];

  return t`Pay $${price} per ${period}`;
};

interface CoachingSubscriptionOptionsProps
  extends ComponentProps<typeof RadioGroupCards.Root> {
  subscriptionPlans: SubscriptionPlan[];
}

export const CoachingSubscriptionOptions = forwardRef<
  ElementRef<typeof RadioGroupCards.Root>,
  CoachingSubscriptionOptionsProps
>(({ subscriptionPlans, ...props }: CoachingSubscriptionOptionsProps, ref) => {
  return (
    <RadioGroupCards.Root ref={ref} className="grow" {...props}>
      {subscriptionPlans.map((plan) => {
        return (
          <RadioGroupCards.Item
            asChild
            id={`radio-group-item-${plan.subscriptionPriceId}`}
            key={plan.subscriptionPriceId}
            value={plan.subscriptionPriceId}
            className="gap-2.5 pr-4"
          >
            <div>
              <div className="flex flex-col gap-1">
                <RadioGroupCards.ItemLabel
                  htmlFor={`radio-group-item-${plan.subscriptionPriceId}`}
                >
                  {plan.description}
                </RadioGroupCards.ItemLabel>

                <RadioGroupCards.ItemDescription>
                  {getPaymentDescription(plan.price, plan.durationInMonths)}
                </RadioGroupCards.ItemDescription>
              </div>

              <RadioGroupCards.ItemIndicator className="absolute right-4 top-4" />
            </div>
          </RadioGroupCards.Item>
        );
      })}
    </RadioGroupCards.Root>
  );
});
CoachingSubscriptionOptions.displayName = "CoachingSubscriptionOptions";
