import { useQuery } from "@tanstack/react-query";

import { billingQueriesStore } from "../api";
import { usePatientSubscription } from "./usePatientSubscription";

export const useMonthlyPeriodPlan = () => {
  const { data: patientSubscription } = usePatientSubscription();

  return useQuery(
    billingQueriesStore.stripe.plans._ctx.planForMonthlyPeriod(
      patientSubscription?.currentPeriodPlan?.subscriptionPriceId,
    ),
  );
};
