import { t } from "ttag";

import {
  useMonthlyPeriodPlan,
  usePatientSubscription,
} from "@/domains/billing/hooks";
import { SUBSCRIPTION_DURATION_IN_MONTHS } from "@/shared.constants";
import type { SubscriptionPlan } from "@/shared.types";
import {
  CardPrimitive as Card,
  JarOfPillsIcon,
  Label,
  PillIcon,
  Skeleton,
} from "@/ui";

const getPlanPricing = (
  durationInMonths: SubscriptionPlan["durationInMonths"],
  price: SubscriptionPlan["price"],
) =>
  ({
    [SUBSCRIPTION_DURATION_IN_MONTHS.MONTHLY]: t`$${price}/month`,
    [SUBSCRIPTION_DURATION_IN_MONTHS.QUARTERLY]: t`$${price}/3 months`,
  })[durationInMonths];

export const PlanChangeComparison = () => {
  const { data: patientSubscription } = usePatientSubscription();
  const { data: newPlan } = useMonthlyPeriodPlan();

  const currentPeriodPlan = patientSubscription?.currentPeriodPlan;

  return (
    <Card.Root className="border border-brown-05 bg-transparent">
      <div className="flex gap-1">
        <Label
          htmlFor="current-plan"
          className="inline-flex items-center gap-1 text-brown-07"
        >
          <JarOfPillsIcon className="size-4" />
          {t`Current Plan:`}
        </Label>

        {currentPeriodPlan ? (
          <Card.Description
            id="current-plan"
            className="flex items-center gap-1.5 font-medium"
          >
            {currentPeriodPlan.name}{" "}
            <span className="text-xs text-brown-05">|</span>
            {getPlanPricing(
              currentPeriodPlan.durationInMonths,
              currentPeriodPlan.price,
            )}
          </Card.Description>
        ) : (
          <Skeleton className="h-3 w-28 self-center" />
        )}
      </div>

      <div className="flex gap-1">
        <Label
          htmlFor="new-period-plan"
          className="inline-flex items-center gap-1 text-brown-07"
        >
          <PillIcon className="size-4" />
          {t`New Plan:`}
        </Label>

        {newPlan ? (
          <Card.Description
            id="new-period-plan"
            className="flex items-center gap-1.5 font-medium"
          >
            {newPlan?.name} <span className="text-xs text-brown-05">|</span>
            {getPlanPricing(newPlan?.durationInMonths, newPlan.price)}
          </Card.Description>
        ) : (
          <Skeleton className="h-3 w-28 self-center" />
        )}
      </div>
    </Card.Root>
  );
};
