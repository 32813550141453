import { useQuery } from "@tanstack/react-query";
import { t } from "ttag";

import { getPatientSubscriptionPlan } from "@/api";
import { usePatientQuery } from "@/hooks";
import {
  SIZE,
  SUBSCRIPTION_DURATION_IN_MONTHS,
  SUBSCRIPTION_STATUS,
} from "@/shared.constants";
import type { SubscriptionPlan } from "@/shared.types";
import {
  CardPrimitive as Card,
  CardIcon,
  CardSkeleton,
  IconWrapper,
  Shape10,
} from "@/ui";
import { tw } from "@/utils";
import { SubscriptionActions, SubscriptionAlerts } from "./components";

const getSubscriptionPeriod = (
  durationInMonths: SubscriptionPlan["durationInMonths"],
) => {
  const period = {
    [SUBSCRIPTION_DURATION_IN_MONTHS.MONTHLY]: t`Monthly Subscription: `,
    [SUBSCRIPTION_DURATION_IN_MONTHS.QUARTERLY]: t`Quarterly Subscription: `,
  }[durationInMonths];

  return period;
};

const getBillingPeriod = (
  durationInMonths: SubscriptionPlan["durationInMonths"],
) => {
  const period = {
    [SUBSCRIPTION_DURATION_IN_MONTHS.MONTHLY]: t`Billing every 28 days`,
    [SUBSCRIPTION_DURATION_IN_MONTHS.QUARTERLY]: t`Billing every 3 months`,
  }[durationInMonths];

  return period;
};

const getPriceDescription = (
  durationInMonths: SubscriptionPlan["durationInMonths"],
) => {
  const priceDescription = {
    [SUBSCRIPTION_DURATION_IN_MONTHS.MONTHLY]: t`month`,
    [SUBSCRIPTION_DURATION_IN_MONTHS.QUARTERLY]: t`3 months`,
  }[durationInMonths];

  return priceDescription;
};

export const CurrentSubscriptionCard = () => {
  const { data, isLoading: isLoadingSubscriptionStatus } = usePatientQuery();
  const status = data?.subscriptionStatus;

  const { data: patientSubscription, isLoading: isLoadingPlan } = useQuery({
    ...getPatientSubscriptionPlan(),
    select: ({ currentPeriodPlan, nextPeriodPlan }) => ({
      nextPeriodPlan,
      currentPeriodPlan,
    }),
  });

  const isLoading = isLoadingSubscriptionStatus || isLoadingPlan;

  if (isLoading) {
    return <CardSkeleton className="flex-1" />;
  }

  if (!patientSubscription || !status) {
    return null;
  }

  const { currentPeriodPlan, nextPeriodPlan } = patientSubscription;

  const isCancelled =
    status === SUBSCRIPTION_STATUS.CANCELLED ||
    status === SUBSCRIPTION_STATUS.PAYMENT_FAILED;

  return (
    <Card.Root
      className={tw("z-10 flex-1 gap-2.5", isCancelled && "text-brown-06")}
    >
      <Card.Background
        className={tw(
          "items-end bg-salmon-03 text-salmon-04",
          isCancelled && "bg-brown-02 text-brown-01",
        )}
      >
        <Shape10 className="size-64 translate-x-11 translate-y-11 -scale-y-100 opacity-70" />
      </Card.Background>

      {currentPeriodPlan && (
        <Card.Header className="gap-2 sm:flex-row sm:items-center sm:justify-between">
          <div className="flex items-center gap-2">
            <Card.Description
              className={tw(
                "flex items-center gap-1.5 text-salmon-09",
                isCancelled && "text-brown-06",
              )}
            >
              <IconWrapper
                as="span"
                size={SIZE.SMALL}
                className={tw("text-salmon-08", isCancelled && "text-brown-06")}
              >
                <CardIcon />
              </IconWrapper>

              <span>
                {getSubscriptionPeriod(currentPeriodPlan.durationInMonths)}
                <strong
                  key={currentPeriodPlan.name}
                  className={tw(
                    "pl-1 text-lg text-salmon-10",
                    isCancelled && "line-through",
                  )}
                >
                  {currentPeriodPlan.name}
                </strong>
              </span>
            </Card.Description>
          </div>

          <Card.Description>
            {getBillingPeriod(currentPeriodPlan.durationInMonths)}
          </Card.Description>
        </Card.Header>
      )}

      <Card.Content className="flex grow flex-col gap-2.5">
        {currentPeriodPlan && (
          <Card.Description
            className={tw(
              "font-serif text-3xl text-salmon-08",
              isCancelled && "text-brown-06",
            )}
          >
            <strong
              className={tw(
                "text-5xl leading-snug text-salmon-10",
                isCancelled && "text-brown-06",
              )}
            >
              ${currentPeriodPlan.price}
            </strong>
            <span className="text-5xl">/</span>
            {getPriceDescription(currentPeriodPlan.durationInMonths)}
          </Card.Description>
        )}

        <SubscriptionAlerts
          status={status}
          currentPeriodPlan={currentPeriodPlan}
          nextPeriodPlan={nextPeriodPlan}
        />
      </Card.Content>

      <Card.Footer className="flex-col-reverse gap-2 sm:flex-row">
        <SubscriptionActions status={status} />
      </Card.Footer>
    </Card.Root>
  );
};
