import { createQueryKeyStore } from "@lukemorales/query-key-factory";
import { skipToken } from "@tanstack/react-query";

import { STRIPE_DOMAIN } from "@/api";
import type { SubscriptionPlan } from "@/shared.types";
import { getPatientSubscription } from "./patient-subscription";
import { getCoachingPlans, getMonthlySubscriptionPlan } from "./stripe";

export const billingQueriesStore = createQueryKeyStore({
  [STRIPE_DOMAIN]: {
    plans: {
      queryKey: null,
      contextQueries: {
        patient: () => ({
          queryKey: ["getPatientSubscription"],
          queryFn: getPatientSubscription,
        }),

        planForMonthlyPeriod: (
          subscriptionPriceId?: SubscriptionPlan["subscriptionPriceId"],
        ) => ({
          queryKey: [subscriptionPriceId],
          queryFn: subscriptionPriceId
            ? () => getMonthlySubscriptionPlan(subscriptionPriceId)
            : (skipToken as never),
        }),

        coachingPlans: () => ({
          queryKey: ["getCoachingPlans"],
          queryFn: getCoachingPlans,
        }),
      },
    },
  },
});
