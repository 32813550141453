import { t } from "ttag";
import { z } from "zod";

export const getChangeSubscriptionSchema = () =>
  z.object({
    subscriptionPriceId: z
      .string()
      .min(1, { message: t`Please choose one of the available plans` }),

    hasConsent: z.literal<boolean>(true, {
      errorMap: () => ({
        message: t`To continue, please check the "I agree" box`,
      }),
    }),
  });
