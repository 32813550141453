import type { Dispatch, SetStateAction } from "react";
import { createContext, useContext, useState } from "react";
import { Outlet } from "react-router-dom";

import { LoadingScreen } from "@/components";
import { usePatientSubscription } from "@/domains/billing/hooks";
import { usePatientQuery } from "@/hooks";
import type { Patient, PatientSubscription } from "@/shared.types";
import type {
  ChooseJourney,
  MainInfo,
  Measurements,
  ScheduleVideoConsultation,
} from "../models";

interface EmbeddablesForms {
  patient: Patient;
  patientSubscription: PatientSubscription;
  mainInfoFormValues?: MainInfo;
  measurementsFormValues?: Measurements;
  chooseJourneyFormValues?: ChooseJourney;
  scheduleVideoConsultationFormValues?: ScheduleVideoConsultation;

  actions: {
    setMainInfoFormValues: Dispatch<SetStateAction<MainInfo | undefined>>;
    setMeasurementsFormValues: Dispatch<
      SetStateAction<Measurements | undefined>
    >;
    setChooseJourneyFormValues: Dispatch<
      SetStateAction<ChooseJourney | undefined>
    >;
    setScheduleVideoConsultationFormValues: Dispatch<
      SetStateAction<ScheduleVideoConsultation | undefined>
    >;
  };
}

export const EmbeddablesFormsContext = createContext<EmbeddablesForms | null>(
  null,
);

export const EmbeddablesFormContextProvider = () => {
  const [mainInfoFormValues, setMainInfoFormValues] = useState<MainInfo>();
  const [chooseJourneyFormValues, setChooseJourneyFormValues] =
    useState<ChooseJourney>();
  const [
    scheduleVideoConsultationFormValues,
    setScheduleVideoConsultationFormValues,
  ] = useState<ScheduleVideoConsultation>();
  const [measurementsFormValues, setMeasurementsFormValues] =
    useState<Measurements>();

  const { data: patient, isLoading, isSuccess } = usePatientQuery();
  const {
    data: patientSubscription,
    isLoading: isLoadingPatientSubscription,
    isSuccess: isSuccessPatientSubscription,
  } = usePatientSubscription();

  if (isLoading || isLoadingPatientSubscription) {
    return <LoadingScreen />;
  }

  if (isSuccess && isSuccessPatientSubscription) {
    return (
      <EmbeddablesFormsContext.Provider
        value={{
          patient,
          patientSubscription,
          mainInfoFormValues,
          measurementsFormValues,
          chooseJourneyFormValues,
          scheduleVideoConsultationFormValues,

          actions: {
            setMainInfoFormValues,
            setMeasurementsFormValues,
            setChooseJourneyFormValues,
            setScheduleVideoConsultationFormValues,
          },
        }}
      >
        <Outlet />
      </EmbeddablesFormsContext.Provider>
    );
  }

  return null;
};

export const useEmbeddablesForms = () => {
  const context = useContext(EmbeddablesFormsContext);

  if (!context) {
    throw new Error(
      "useEmbeddablesForms must be used within a <EmbeddablesFormsContext.Provider />",
    );
  }

  return context;
};
